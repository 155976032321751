import { apiClient, downloadApiClient } from "./HttpService";

// 待审核订单列表接口
export function fetchToExamineList(params) {
  let url = `/order-approvals?consigneeTel=${params.consigneeTel}&businessVenuName=${params.businessVenuName}&orderSn=${params.orderSn}&orderStatus=${params.orderStatus}&orderType=${params.orderType}&pageNum=${params.pageNum}&pageSize=${params.pageSize}&paymentType=${params.paymentType}`;
  if (params.startTime) {
    url += `&startTime=${params.startTime}`;
  }
  if (params.endTime) {
    url += `&endTime=${params.endTime}`;
  }
  return apiClient.get(url);
}

// 待审核订单详情
export function toExamineDetail(orderSn) {
  return apiClient.get(`/order-approvals/${orderSn}`);
}

// 审核订单
export function approvalOrder(params) {
  return apiClient.patch(
    `/order-approvals/${params.orderSn}?isAudit=${params.isAudit}&orderCycle=${
      params.orderCycle
    }&dismissReason=${params.dismissReason ? params.dismissReason : ""}`,
    params.list
  );
}

// 查询未交付订单列表
export function queryUndeliveredOrderList(params) {
  let url = `/procure-orders/undelivered?approveFlag=${params.approveFlag}&consigneeName=${params.consigneeName}&consigneeTel=${params.consigneeTel}&factoryId=${params.factoryId}&orderSn=${params.orderSn}&pageNum=${params.pageNum}&pageSize=${params.pageSize}&businessVenuName=${params.dealerName}`;
  if (params.startTime) {
    url += `&startTime=${params.startTime}`;
  }
  if (params.endTime) {
    url += `&endTime=${params.endTime}`;
  }
  return apiClient.get(url);
}

// 查询无效订单列表
export function queryInvalidOrderList(params) {
  let url = `/procure-orders/invalid?approveFlag=${params.approveFlag}&consigneeName=${params.consigneeName}&consigneeTel=${params.consigneeTel}&factoryId=${params.factoryId}&orderSn=${params.orderSn}&pageNum=${params.pageNum}&pageSize=${params.pageSize}&businessVenuName=${params.dealerName}`;
  if (params.startTime) {
    url += `&startTime=${params.startTime}`;
  }
  if (params.endTime) {
    url += `&endTime=${params.endTime}`;
  }
  return apiClient.get(url);
}

// 发货查询订单信息
export function queryOrderInfo(orderSn) {
  return apiClient.get(`/procure-orders/${orderSn}/delivery-form`);
}

// 根据原厂查询发货人地址列表
export function fetchAddressList(factoryId) {
  return apiClient.get(`/sender/addresses/${factoryId}`);
}

// 查询地址详情
export function fetchAddressDetail(addressId) {
  return apiClient.get(`/sender/address/${addressId}`);
}

// 添加发货人地址
export function addSenderAddress(params) {
  return apiClient.post("/sender/address", params);
}

// 修改发货人地址
export function modifySenderAddress(params) {
  return apiClient.put("/sender/address", params);
}

// 删除发货人地址
export function deleteSenderAddress(senderId) {
  return apiClient.delete(`/sender/address/${senderId}`);
}

// 未交付订单接口
export function fetchUndeliveredDetail(procureOrderSn) {
  return apiClient.get(`/procure-orders/${procureOrderSn}`);
}
// 已交付订单列表
export function fetchDeliveredList(params) {
  return apiClient.get(
    `/procure-orders/completed?consigneeName=${params.consigneeName}&consigneeTel=${params.consigneeTel}&businessVenuName=${params.businessVenuName}&endTime=${params.endTime}&factoryId=${params.factoryId}&orderSn=${params.orderSn}&pageNum=${params.pageNum}&pageSize=${params.pageSize}&startTime=${params.startTime}`
  );
}
// 查询关联订单接口
export function fetchRelationOrder(orderSn) {
  return apiClient.get(`/procure-orders/associated/${orderSn}`);
}

// 提交发货
export function onDeliverGoods(params) {
  return apiClient.post("/logistics", params);
}

// 发货明细
export function onDeliverDetail(params) {
  return apiClient.get(
    `/delivered-goods-details?logisticsId=${params.logisticsId}&orderSn=${params.orderSn}`
  );
}

// 拼单列表
export function fetchSpellList(params) {
  let url = `/patchwork-orders?consigneeAddress=${params.consigneeAddress}&orderSn=${params.orderSn}&factoryId=${params.factoryId}&orderStatus=${params.orderStatus}&pageNum=${params.pageNum}&pageSize=${params.pageSize}`;
  if (params.totalVolumeMax) {
    url += `&totalVolumeMax=${params.totalVolumeMax}`;
  }
  if (params.totalVolumeMin) {
    url += `&totalVolumeMin=${params.totalVolumeMin}`;
  }
  if (params.totalWeightMax) {
    url += `&totalWeightMax=${params.totalWeightMax}`;
  }
  if (params.totalWeightMin) {
    url += `&totalWeightMin=${params.totalWeightMin}`;
  }
  if (params.startTime) {
    url += `&startTime=${params.startTime}`;
  }
  if (params.endTime) {
    url += `&endTime=${params.endTime}`;
  }
  return apiClient.get(url);
}

// 拼单接口
export function patchOrder(params) {
  return apiClient.post(
    `/patchwork-orders/${params.factoryId}/patchworks`,
    params.orderSns
  );
}

// 拼单详情
export function patchOrderDetail(factoryId, orderSn) {
  return apiClient.get(`/patchwork-orders/${factoryId}/patchwork/${orderSn}`);
}

// 导入生产系统
export function onImportProduct(params) {
  return apiClient.patch(
    `/procure-orders/${params.orderSn}?status=${params.status}`
  );
}

// 验证skucode
export function verifySkuCodeAndGoods(params) {
  return apiClient.post("/logistics/verification", params);
}

// vin校验
export function onVinChecl(list) {
  return apiClient.post("/goods/exists", list);
}

// 撤销拼单
export function revokeSpelling(orderSn) {
  return apiClient.put(`/patchwork-orders/revocation/${orderSn}`);
}

// 导出发货清单
export function exportShippingList(orderSn) {
  return downloadApiClient.post(
    `/consignment/download/import-manifest?orderSn=${orderSn}`
  );
}

// 根据订单编号查询未发货信息
export function fetchUndeliverByOrderSn(orderSn) {
  return apiClient.get(`/procure-orders/${orderSn}/order-skus`);
}

// 支付完成
export function finishPay(orderSn) {
  return apiClient.patch(`/pay/offline-payment/${orderSn}`);
}

// 根据订单查询出货单
export function fetchSaleBillByOrderSn(orderSn) {
  return apiClient.get(`/consignment/${orderSn}`);
}

// 根据发货单获取收货单
export function fetchReceiveBillBySaleBill(consignmentId) {
  return apiClient.get(`/receiving/${consignmentId}`);
}

// -----零售订单相关接口-----
// 零售订单列表
export function getRetailOrder(params) {
  return apiClient.get(
    `/retail/order?customerName=${params.customerName}&corporationId=${params.corporationId}&startTime=${params.startTime}&endTime=${params.endTime}&areaCode=${params.areaCode}&pageSize=${params.pageSize}&pageNum=${params.pageNum}`
  );
}

// 零售订单详情
export function getRetailOrderDetail(orderSn) {
  return apiClient.get(`/retail/order/${orderSn}`);
}

// 查询收货人信息和地址
export function fetchReceiverAddressInfo(venuId) {
  return apiClient.get(`/consignee?venuId=${venuId}`);
}

// 获取商品进货价
export function fetchProductPrice(skuId, corporationId) {
  return apiClient.get(`/products-index/${skuId}/${corporationId}/show-price`);
}

// 批量获取产品价格
export function batchFetchProductPrice(distributorId, skuIds) {
  return apiClient.get(
    `/products-index/${distributorId}/show-price/batch?skuIds=${skuIds}`
  );
}

// 下单
export function placeOrder(params) {
  return apiClient.post(`/orders/purchase`, params);
}
